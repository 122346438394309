import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddressForm from "./AddressForm";
import PaymentForm from "./PaymentForm";
import Review from "./Review";
import BagPreview from "../BagPreview";

const steps = ["Henvendelse"];

//props.buyBag({ ...formData, ...{ bag: props.bag } });

function getStepContent(step, formData, handleFormFieldChange, bag, errors) {
  switch (step) {
    case 0:
      return (
        <AddressForm
          errors={errors}
          formData={formData}
          handleFormFieldChange={handleFormFieldChange}
        />
      );
    case 1:
      return <Review formData={formData} bag={bag} />;
    default:
      throw new Error("Unknown step");
  }
}

export default function Checkout(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState({
    phone: "",
    firstName: "",
    lastName: "",
    email: "",
    address1: "",
    zip: "",
    city: "",
  });

  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    if (Object.keys(errors).length > 0) {
      validateFields(activeStep);
    }
  }, [formData]);

  function handleFormFieldChange(update) {
    const { name, value } = update;

    console.log("FORM FIELD UPDATE name, value: ", name, value);

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function validateFields(step) {
    let fields = [];
    let errorFields = [];
    let validFields = [];

    switch (step) {
      case 0: // Forsendelse
        fields = ["firstName", "lastName", "address1", "city", "zip"];

        fields.forEach((field) => {
          if (formData[field] !== "") {
            validFields.push(field);
          } else {
            errorFields.push(field);
          }
        });

        // Handle email
        let isValidEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
        let emailIsVaild = formData["email"].match(isValidEmail);

        setErrors((state) => {
          const newState = { ...state };
          validFields.map((f) => delete newState[f]);
          errorFields.map((f) => (newState[f] = "Skal udfyldes"));

          if (emailIsVaild) {
            delete newState["email"];
          } else {
            newState["email"] = "Ugyldig email";
          }

          return newState;
        });

        return errorFields.length === 0;
        break;

      case 1: // Betaling
        let value = formData["phone"];

        let trimmedPhoneValue = value.split("+45")[1] ?? "";
        var trimmedPhoneValueNoSpaces = trimmedPhoneValue.replace(/\s+/g, "");

        let msg = undefined;

        if (trimmedPhoneValueNoSpaces === "") {
          msg = "* skal udfyldes";
        } else if (trimmedPhoneValueNoSpaces.length !== 8) {
          msg = "* skal være 8 cifre";
        }

        if (msg === undefined) {
          setErrors((state) => {
            const newState = { ...state };
            delete newState["phone"];
            return newState;
          });

          return true; // because valid
        } else {
          setErrors((state) => {
            const newState = { ...state };
            newState["phone"] = msg;
            return newState;
          });

          return false; // because invalid
        }

        break;
      default:
        break;
    }
  }

  const handleNext = () => {
    let valid = validateFields(activeStep);
    if (valid) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const completeOrder = () => {
    let valid = validateFields(activeStep);
    if (valid) {
      setActiveStep(activeStep + 1);
      props.buyBag({ ...formData, ...{ bag: props.bag } });
    }
  };

  return (
    <div>
      <React.Fragment>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              Tak for din bestilling
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <p>Du har valgt at bestille en Lefafa, tak for det. </p>
              <p>
                Vi sender en bekræftelse på modtagelse af din bestilling
                indenfor 24 timer. Afregning samt forventet levering aftales
                nærmere på mail.
              </p>
            </Typography>
            <Typography variant="subtitle1">Med venlig hilsen</Typography>
            <Typography variant="subtitle1" gutterBottom>
              Team Lefafa
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <BagPreview bag={props.bag} img={props.img} />

            {getStepContent(
              activeStep,
              formData,
              handleFormFieldChange,
              props.bag,
              errors
            )}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Tilbage
                </Button>
              )}

              <Button
                variant="contained"
                onClick={completeOrder}
                completeOrder
                sx={{ mt: 3, ml: 1 }}
              >
                Send
              </Button>
            </Box>
          </React.Fragment>
        )}
      </React.Fragment>
    </div>
  );
}
