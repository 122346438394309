import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MuiPhoneNumber from "material-ui-phone-number";


export default function AddressForm(props) {
  console.log("ADRESS with props ", props);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Forsendelse
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={props.errors["firstName"] ? true : false}
            helperText={props.errors["firstName"]}
            variant="filled"
            id="firstName"
            name="firstName"
            label="Fornavn"
            fullWidth
            autoComplete="given-name"
            value={props.formData.firstName}
            onChange={(e) =>
              props.handleFormFieldChange({
                name: "firstName",
                value: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={props.errors["lastName"] ? true : false}
            helperText={props.errors["lastName"]}
            variant="filled"
            id="lastName"
            name="lastName"
            label="Efternavn"
            fullWidth
            autoComplete="family-name"
            value={props.formData.lastName}
            onChange={(e) =>
              props.handleFormFieldChange({
                name: "lastName",
                value: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            error={props.errors["address1"] ? true : false}
            helperText={props.errors["address1"]}
            variant="filled"
            id="address1"
            name="address1"
            label="Addresse"
            fullWidth
            autoComplete="shipping address-line1"
            value={props.formData.address1}
            onChange={(e) =>
              props.handleFormFieldChange({
                name: "address1",
                value: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={props.errors["zip"] ? true : false}
            helperText={props.errors["zip"]}
            variant="filled"
            id="zip"
            name="zip"
            label="Postnummer"
            fullWidth
            autoComplete="shipping postal-code"
            value={props.formData.zip}
            onChange={(e) =>
              props.handleFormFieldChange({
                name: "zip",
                value: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={props.errors["city"] ? true : false}
            helperText={props.errors["city"]}
            variant="filled"
            id="city"
            name="city"
            label="By"
            fullWidth
            autoComplete="shipping address-level2"
            value={props.formData.city}
            onChange={(e) =>
              props.handleFormFieldChange({
                name: "city",
                value: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            error={props.errors["email"] ? true : false}
            helperText={props.errors["email"]}
            variant="filled"
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
            value={props.formData.email}
            onChange={(e) =>
              props.handleFormFieldChange({
                name: "email",
                value: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={6}>
        <div style={{ display: "flex", flexDirection: "column" }}>
            <MuiPhoneNumber
              error={props.errors["phone"] ? true : false}
              helperText={props.errors["phone"]}
              variant="filled"
              name="phone"
              label="Telefon"
              data-cy="user-phone"
              defaultCountry={"dk"}
              onlyCountries={["dk"]}
              countryCodeEditable={false}
              value={props.formData.phone}
              onChange={(e) =>
                props.handleFormFieldChange({ name: "phone", value: e })
              }
            />
          </div>
        </Grid>


      </Grid>
    </React.Fragment>
  );
}
