import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";

export default function Review(props) {
  let freightPrice = 39;
  const products = [
    {
      name: `Lefafa nr. ${props.bag.bag_nr}`,
      desc: "Eventuelt en beskrivelse",
      price: `${props.bag.price} kr`,
    },
    { name: "Fragt", desc: "Sendes med DAO", price: `${freightPrice} kr` },
  ];

  console.log("REVIEW her er props ", props);

  const addresses = [
    props.formData.address1,
    props.formData.zip,
    props.formData.city,
  ];
  const payments = [{ name: "Mobilepay" }, { name: props.formData.phone }];

  const getTotalPrice = function () {
    let total = freightPrice + props.bag.price;
    return `${total} kr`;
  };

  return (
    <React.Fragment>
      <List disablePadding>
        {products.map((product) => (
          <ListItem key={product.name} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={product.name} secondary={product.desc} />
            <Typography variant="body2">{product.price}</Typography>
          </ListItem>
        ))}

        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {getTotalPrice()}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Forsendelse
          </Typography>
          <Typography
            gutterBottom
          >{`${props.formData.firstName} ${props.formData.lastName}`}</Typography>
          <Typography gutterBottom>{addresses.join(", ")}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Betaling
          </Typography>
          <Grid container>
            {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
