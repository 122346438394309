import * as React from "react";

import BagCaption from "./BagCaption";

const BagPreview = (props) => {
  return (
    <div className="bag" style={{ margin: "auto", width: "60%" }}>
      <img src={props.img}></img>

      <BagCaption bag={props.bag} />
    </div>
  );
};

export default BagPreview;
