import * as React from "react";
import "../styles/main.css";

import Layout from "../components/Layout";
import OrderForm from "../components/OrderForm";

const OrderPage = (props) => {
  console.log("HER ER PROPS for order ", props);

  if (props.location?.state?.item === undefined) {
    return null;
  }

  return (
    <Layout>
      <OrderForm
        bag={props.location.state.item}
        img={props.location.state.img}
      />
    </Layout>
  );
};

export default OrderPage;
