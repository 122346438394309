import React from "react";
import { useForm } from "react-hook-form";
import Checkout from "./Checkout/Checkout";

export default function OrderForm(props) {
  console.log("---------HER ER ORDERFORM props ", props);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm();

  console.log("isSubmitting is ", isSubmitting);

  const onSubmit = (data) => buyBag(data);

  const buyBag = async function (data) {
    console.log("BUY bag called witg ", data);

    const options = {
      method: "POST",
      body: JSON.stringify(data),
    };

    return fetch("/.netlify/functions/buy_bag", options).then((res) =>
      res.json().then((data) => console.log(data))
    );

    // TODO succes hvis alt er godt {ok: true, msg: ""}
    // TODO error håndtering hvis buy bag failer {ok: false, error: ""}
  };

  console.log("ERRORS: ", errors);

  if (isSubmitted) {
    return <h1>DONE</h1>;
  }

  return <Checkout bag={props.bag} img={props.img} buyBag={buyBag} />;
}
